<template>
  <div class="pagination">
    <div class="prev">
      <img :src="prevIcon" alt="prev" />
    </div>

    <ul ref="timeLineList" :class="`active-${active}`">
      <li ref="timeLineItem" v-for="item of count" :key="item"></li>
    </ul>

    <div class="next">
      <img :src="nextIcon" alt="next" />
    </div>
  </div>
</template>

<script>
import nextIcon from '../../../assets/profile_assets/cevron-right-orange.svg';
import prevIcon from '../../../assets/profile_assets/chevron-left.svg';

import VueTypes from 'vue-types';

export default {
  name: 'TimeLine',
  props: {
    active: VueTypes.number.def(4),
  },
  computed: {
    activeItem() {
      return this.active - 1;
    },
  },
  data() {
    return {
      count: 6,
      nextIcon,
      prevIcon,
    };
  },
  mounted() {
    const timeLineItems = this.$refs.timeLineItem;

    timeLineItems.forEach((item, index) => {
      index === this.activeItem
        ? item.style = 'background: #6610F5; width: 12px; height: 12px'
        : '';

      index > this.activeItem
        ? item.style = 'background: #A5A5A5'
        : '';
    });
  },
};
</script>

<style lang="scss" scoped>
  .pagination {
    display: none;
  }

  @media (max-width: $mobile) {
    .pagination {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 42px;
      margin-top: 0;
      z-index: 0;

      .prev {
        transform: rotate(180deg);
        display: flex;
        align-items: center;
      }

      .next {
        display: flex;
        align-items: center;
      }

      ul {
        display: flex;
        align-items: center;
        position: relative;

        li {
          width: 8px;
          height: 8px;
          background: $main;
          border-radius: 50%;
          margin: 0 8px;
          position: relative;
          z-index: 2;
        }

        @for $i from 3 through 6 {
          &.active-#{$i}::after {
            content: '';
            display: block;
            position: absolute;
            left: 10%;
            height: 1px;
            z-index: 1;
            background: $main;

            @if $i == 3 {
              width: calc(100% - 100px);
            } @else if $i == 4 {
              width: calc(100% - 70px);
            } @else if $i == 5 {
              width: calc(100% - 50px);
            } @else {
              width: 85%;
            }
          }
        }

        &::before {
          display: block;
          content: '';
          position: absolute;
          left: 10%;
          width: 85%;
          height: 1px;
          background: #A5A5A5;
          z-index: 1;
        }
      }
    }

  }

</style>