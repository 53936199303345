<template>
  <div class="register_photo">
    <time-line :active="4"></time-line>

    <div class="top_card">
      <router-link :to="{ name: 'home', params: { lang: 'uz' } }">
        <img src="@/assets/uzum-logo.svg" alt="" />
      </router-link>
      <h2>{{ localize('register-photo')['passport-address'] }}</h2>
      <p class="example">
        {{ localize('register-photo')['example-address-text-card'] }}
      </p>
    </div>
    <div class="form_input">
      <div class="image-container">
        <img
          v-if="previewUrl"
          class="preview-address-passport"
          :src="previewUrl"
          alt="Preview Address Passport"
        />
        <img
          v-else
          src="../../assets/img/id_card_address.svg"
          alt="Placeholder Address ID card"
        />
      </div>

      <div
        class="file_passport"
        :class="{ 'file-download': filePassportAddress }"
      >
        <input
          type="file"
          id="fileSelfie"
          accept=".jpg, .png, .jpeg"
          style="display: none"
          ref="fileSelfie"
          :disabled="filePassportAddress"
          @change="pickPhoto($event)"
        />

        <label for="fileSelfie" :class="{ disabled: filePassportAddress }">
          <img
            v-if="!filePassportAddress"
            src="../../assets/icons/add.svg"
            alt=""
          />
          <img
            v-else-if="isPassportAddressSuccess"
            src="../../assets/icons/checked.svg"
            alt=""
          />
          <img v-else src="../../assets/icons/filePassport.svg" alt="" />
        </label>

        <span @click="deletePhoto" v-if="filePassportAddress" class="d-block">
          {{ localize('button')['delete'] }}
        </span>

        <p
          v-if="filePassportAddress"
          class="d-none-sm"
          v-html="localize('register-photo')['download-address-file']"
        ></p>

        <p
          v-else
          class="d-none-sm"
          v-html="localize('register-photo')['passport-address']"
        ></p>

        <p class="d-block">
          {{ localize('register-photo')['passport-address'] }}
        </p>

        <span v-if="filePassportAddress" class="d-none-sm" @click="deletePhoto">
          {{ localize('button')['delete'] }}
        </span>
      </div>
    </div>

    <div class="form_input btn-bottom">
      <main-button :is-loading="loading" @click="loadingHandler">
        {{ localize('button')['uploadPhoto'] }}
      </main-button>
    </div>
  </div>
</template>

<script>
import localize from '../../filters/localize.filter';
import Cookies from 'js-cookie';
import MainButton from '../../components/buttons/MainButton';
import TimeLine from '../../components/mobile/bars/TimeLine';

export default {
  name: 'UploadAddressPassport',
  components: {
    MainButton,
    TimeLine,
  },
  data: () => ({
    loading: false,
    filePassport: [],
    fileSelfie: [],
    previewUrl: null,
    active: false,
    selfie: '',
    passport: '',
    isPassportAddressSuccess: false,
    filePassportAddress: null,
    api_token: Cookies.get('token'),
  }),
  methods: {
    localize,
    loadingHandler() {
      this.$toasted.clear();
      if (this.filePassportAddress) {
        this.loading = true;

        const formData = new FormData();
        formData.append('id_with_address', this.filePassportAddress);
        formData.append('step', '2');
        formData.append('type', '2');

        this.$axios
          .post('buyer/verify/modify', formData)
          .then((response) => {
            if (response.data.status === 'success') {
              this.isPassportAddressSuccess = true;

              this.$store.dispatch('buyers/userStatus').then(() => {
                this.loading = false;
                this.$router.push({ name: 'upload-selfie-id-card' });
              });
            } else {
              this.$toasted.clear();

              this.$store.dispatch('buyers/userStatus').then(() => {
                this.loading = false;
                this.$router.push({ name: 'upload-selfie-passport' });
              });
            }
          })
          .catch((e) => {
            this.loading = false;

            this.$toastError(e.message);
          });
      } else {
        this.$toastError(localize('error')['upload']);
      }
    },
    pickPhoto(e) {
      this.filePassportAddress = e.target.files[0];
      this.previewUrl = URL.createObjectURL(e.target.files[0]);
    },
    deletePhoto() {
      if (this.filePassportAddress) {
        this.$refs.fileSelfie.value = '';
        this.filePassportAddress = null;
        this.previewUrl = null;
        // window.location.reload();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .d-none-sm {
    display: inline-block;
  }

  .d-block {
    display: none !important;
  }

  .top_card {
    padding-top: 17%;

    img {
      margin-bottom: 56px;
    }

    h2 {
      font-weight: 900;
      font-size: 40px;
      line-height: 40px;
      color: $black;
      margin-bottom: 1rem;
    }

    p {
      font-weight: 600;
      font-size: 24px;
      line-height: 30px;
      color: $black;
    }
  }

  button {
    max-width: 384px;
    width: 100%;
    outline: none;
    border: none;
    height: 100%;
  }

  .form_input {
    display: flex;
    align-items: center;
    padding: 2rem 0;

    .image-container {
      position: relative;
      width: 230px;
      height: 230px;
      background-color: $white;
      border-radius: 50%;
      margin-right: 42px;

      img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  .file_passport {
    width: 176px;
    height: 176px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: #ffffff;
    text-align: center;
    border-radius: 12px;
    padding: 38px 28px;
    /*margin: 32px 0 0 50px;*/

    &.file-download {
      padding-bottom: 12px;

      &:last-child {
        padding: 38px 14px 12px 14px;
      }
    }

    label {
      width: 48px;
      height: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      background: $main;
      border-radius: 8px;
      margin-bottom: 16px;

      &.disabled {
        background: #27ae60;
        cursor: not-allowed;
      }
    }

    p {
      font-size: 14px;
      line-height: 18px;
      text-align: center;
      letter-spacing: 0.02em;
      color: $black;
    }

    span {
      display: inline-block;
      margin-top: 8px;
      font-size: 14px;
      line-height: 18px;
      text-align: center;
      letter-spacing: 0.02em;
      color: #eb5757;
      cursor: pointer;
    }
  }

  .photo-ui {
    display: flex;
    margin-top: 24px;
    margin-bottom: 55px;

    .info-icon {
      margin-right: 8px;
    }
  }

  .security_form {
    display: flex;
    align-items: center;
    margin-bottom: 36px;
    padding-left: 12px;

    .icon {
      background: $main;
      border-radius: 8px;
      padding: 4px;
      display: flex;
      align-items: center;
    }

    span {
      margin-left: 12px;
      color: $main;
    }
  }

  .security_alert {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba($color: #000000, $alpha: 0.3);
    backdrop-filter: blur(12px);
    overflow: auto;
    padding: 50px;

    .overlay {
      position: fixed;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      z-index: 5;
    }

    &::-webkit-scrollbar {
      width: 5px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: $main;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }

    .card {
      padding: 40px;
      background: white;
      max-width: 820px;
      margin: auto;
      position: relative;
      top: 0;
      z-index: 6;
      text-align: center;

      h1 {
        font-weight: bold;
        font-size: 24px;
        line-height: 28px;
        text-align: center;
        color: $black;
        margin-bottom: 32px;
      }

      &-img {
        width: 100%;
        display: flex;
        justify-content: space-around;

        p {
          margin-top: 25px;
          font-size: 16px;
          line-height: 24px;
          text-align: center;
          letter-spacing: 0.01em;
          color: $black;
        }
      }

      &_back {
        max-width: 385px;
        margin: auto;
        margin-top: 40px;
      }
    }
  }

  button.P_button {
    background: $main;
    cursor: pointer;
    box-shadow: -12px 12px 30px rgba(102, 16, 245, 0.25);
    border-radius: 8px;
    width: 100%;
    padding: 16px 24px;
    font-weight: 600;
    font-size: 15px;
    line-height: 111.63%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    transition: 0.2s;

    &:hover {
      background: $main-light;
    }
  }

  .btn-loading {
    position: relative;
    pointer-events: none;
    color: transparent !important;

    &:after {
      animation: spinAround 500ms infinite linear;
      border: 2px solid $white;
      border-radius: 50%;
      border-right-color: transparent;
      border-top-color: transparent;
      content: '';
      display: block;
      height: 1em;
      width: 1em;
      position: absolute;
      left: calc(50% - (1em / 2));
      top: calc(50% - (1em / 2));
    }
  }

  @keyframes spinAround {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }

  @media (max-width: 1199px) {
    .register_photo {
      height: 100vh;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      text-align: center;

      .photo-ui {
        display: flex;
        margin-top: 24px;
        margin-bottom: 50px;
        font-size: 14px;
      }

      .top_card {
        padding-top: 0px;
      }

      .form_input:nth-child(4) {
        width: 100%;
        justify-content: center;
      }

      button {
        max-width: 332px;
      }
    }
  }

  @media (max-width: 575px) {
    .register_photo {
      justify-content: unset;
      padding-bottom: 100px;
      padding-top: 30px;
    }
    .security_form {
      text-align: left;
      font-weight: normal;
      font-size: 16px;
      line-height: 16px;
      letter-spacing: -0.078px;
      color: $main;
      margin-top: auto;
      margin-bottom: 24px;
    }
    .file_passport.file-download {
      justify-content: unset;

      &:last-child {
        padding: 12px;
      }

      p.d-block {
        margin-right: auto;
        font-size: 14px;
      }

      span.d-block {
        margin-top: 0;
        margin-right: 8px;
      }
    }
    .d-none-sm {
      display: none !important;
    }
    .d-block {
      display: inline-block !important;
    }
    .security_alert {
      padding: 0;
      text-align: left;

      .card {
        h1 {
          font-weight: bold;
          font-size: 24px;
          line-height: 28px;
          letter-spacing: 0.326451px;
        }

        p {
          font-size: 14px;
          line-height: 18px;
          letter-spacing: 0.02em;
          color: $black;
        }

        h3 {
          font-weight: bold;
          font-size: 15px;
          line-height: 18px;
          color: $black;
        }
      }

      .card_back {
        max-width: 100%;
      }
    }
    .top_card img {
      display: none;
    }
    .top_card h2 {
      font-weight: 900;
      font-size: 24px;
      line-height: 20px;
      text-align: center;
      color: $black;
      /*margin-bottom: 24px;*/
    }
    .top_card p {
      font-size: 14px;
      line-height: 20px;
    }
    .form_input {
      width: 100%;
      flex-direction: column;
      align-items: normal;
      padding: 0;

      .image-container {
        width: 170px;
        height: 170px;
        margin: 1rem auto 3.5rem auto;

        img {
          mix-blend-mode: unset;

          &.preview-address-passport {
            width: 205px;
            height: 150px;
          }
        }
      }

      &.btn-bottom {
        margin-top: auto;

        button {
          margin-bottom: 0;
        }
      }
    }
    .file_passport {
      width: auto;
      height: auto;
      flex-direction: row-reverse;
      padding: 12px;
      margin: 0 0 12px;
      justify-content: space-between;

      label {
        margin-bottom: 0;
        width: 48px;
        height: 32px;

        img {
          width: 24px;
          height: 24px;
        }
      }

      p {
        font-weight: normal;
        font-size: 16px;
        line-height: 22px;
        color: $black;

        br {
          display: none;
        }
      }
    }
    .register_photo button {
      max-width: 100%;
    }
    button.P_button {
      margin-bottom: 32px;
    }
  }

  .preview-address-passport {
    width: 205px;
    height: 150px;
    object-fit: cover;
    border-radius: 8px;
  }
</style>
