<template>
  <button
    :disabled="isLoading || disabled"
    :class="[ isLoading ? 'isLoading' : '' ]"
    @click="onClick"
  >
    <slot v-if="!label" />
    <span v-else>{{ label }}</span>
  </button>
</template>

<script>
import VueTypes from 'vue-types';

export default {
  name: 'MainButton',
  props: {
    isLoading: VueTypes.bool.def(false),
    disabled: VueTypes.bool.def(false),
    label: VueTypes.string,
  },
  methods: {
    onClick() {
      this.$emit('click');
    },
  },
};
</script>

<style lang="scss" scoped>
  $secondary: #FFEEE8;
  $font-size: 18px;

  button {
    font-family: 'Inter', sans-serif;
    width: 100%;
    outline: none;
    border-radius: 8px;
    border: none;

    font-weight: 600;
    font-size: $font-size;

    background: $main;
    box-shadow: -12px 12px 30px $box-shadow-color;

    color: $white;
    padding: 18px 24px;
    transition: 0.2s;

    &:hover {
      background: $main-light;
      cursor: pointer;
    }

    &:disabled {
      background-color: $disabled;
      box-shadow: unset;
    }

    &.isLoading {
      position: relative;
      pointer-events: none;
      color: transparent !important;

      &:after {
        display: block;
        content: '';
        height: 1em;
        width: 1em;
        position: absolute;
        left: calc(50% - (1em / 2));
        top: calc(50% - (1em / 2));

        border: 2px solid $white;
        border-radius: 50%;
        border-right-color: transparent;
        border-top-color: transparent;

        animation: spinAround 500ms infinite linear;
      }
    }
  }

  @keyframes spinAround {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }

  @media (max-width: 575px) {
    button {
      text-align: center;
      font-size: $font-size - 3px;
      line-height: 18px;
      color: $white;
      padding: 16px 24px;
    }
  }
</style>